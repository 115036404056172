import { Component, Input, OnInit } from '@angular/core';
import { OfficeLocation } from '@twbi/types';
import { fadeIn } from '../../animations';

@Component({
  selector: 'twbi-location-info-block',
  templateUrl: './location-info-block.component.html',
  styleUrls: ['./location-info-block.component.scss'],
  animations: [fadeIn]
})
export class LocationInfoBlockComponent implements OnInit {

  @Input() location: OfficeLocation;

  constructor() {
  }

  ngOnInit() {
  }

  hasArrayProp(o, name) {
    return (name in o && o[name].length > 0);
  }

  locationHasPhoto( location: OfficeLocation ): boolean {
    return location && 'photo' in location && Object.keys( location.photo ).length !== 0
  }
}
