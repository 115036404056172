import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms/cms.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { JobPosting } from '@twbi/types';
import * as PrismicDOM from 'prismic-dom';

@Component({
  selector: 'twbi-application-dialog',
  templateUrl: './application-dialog.component.html',
  styleUrls: ['./application-dialog.component.scss']
})
export class ApplicationDialogComponent implements OnInit {

  job: JobPosting;

  loading = true;

  constructor(private cms: CmsService,
              private router: Router,
              private route: ActivatedRoute) {
    this.loading = true;
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('slug')),
      switchMap(slug => this.cms.getByKey('jobposting', slug))
    ).subscribe((resp: any) => {
      this.job = <JobPosting>{
        ...resp.data,
        slug: resp.uid,
        job_description: PrismicDOM.RichText.asHtml(resp.data.job_description, this.cms.linkResolver),
      };

      this.loading = false;
    });

  }

  ngOnInit() {
  }

  public closeOverlay() {
    this.router.navigate([{outlets: {ol: null}}]);
  }
}
