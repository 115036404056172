import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { RouterOutlet } from '@angular/router';
import { fadeAnimation, routeAnimations } from './animations';
import { RouterHelperService } from './services/router-helper/router-helper.service';
import { BrowserService } from './shared/services/browser/browser.service';

@Component({
  selector: 'twbi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations, fadeAnimation]
})
export class AppComponent implements OnInit {
  title = 'twbi';

  constructor(private routerHelpter: RouterHelperService, private browser: BrowserService) {
  }

  ngOnInit() {
    if (!this.browser.isBrowser) return;
    AOS.init({once: true});
    this.routerHelpter.setInitialScrollPositionTopOnRouteChange();
    this.routerHelpter.initAnalyticsTracking();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


}
