import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'a-ghost-span',
  templateUrl: './ghost-span.component.html',
  styleUrls: ['../ghost.scss', './ghost-span.component.scss']
})
export class GhostSpanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
