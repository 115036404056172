import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const respMenuAnimation = trigger('respMenuAnimation', [
  transition(':enter', [
    style({opacity: '0'}),
    query('li a', style({opacity: 0}), {optional: true}),
    animate('200ms ease-in', style({opacity: '1'})),

    query('li a', stagger('100ms', [
      animate('0.5s 0.1s ease-in', keyframes([
        style({opacity: 0, transform: 'translateY(+50%)', offset: 0}),
        style({opacity: 0.8, transform: 'translateY(-5px)', offset: 0.3}),
        style({opacity: 1, transform: 'translateY(0)', offset: 1}),
      ]))]), {optional: true}),
  ]),
  transition(':leave', [
    animate('500ms ease-in', style({opacity: '0'})),
  ])
]);
