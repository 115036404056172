import { Component, Input, OnInit } from '@angular/core';
import { ContactPerson } from '@twbi/types';

@Component({
  selector: 'twbi-contact-person-card',
  templateUrl: './contact-person-card.component.html',
  styleUrls: ['./contact-person-card.component.scss']
})
export class ContactPersonCardComponent {

  @Input() contactPerson: ContactPerson;


}
