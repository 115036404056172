import { Component, Input, OnInit } from '@angular/core';
import { Service } from '@twbi/types';

@Component({
  selector: 'twbi-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {


  @Input() servicesLink = '/leistungen';
  @Input() service: Service;

  constructor() {
  }

  ngOnInit() {
  }

}
