import { Injectable } from '@angular/core';
import { CookieOption } from './cookie-notice.type';
import { environment } from '../../../../environments/environment';
import { BrowserService } from '../browser/browser.service';

@Injectable({
  providedIn: 'root',
})


export class CookieNoticeService {

  // This array specifies which types of cookies are available on this side and if they are preselected or disabled in the cookien notice
  private cookieOptions: Array<CookieOption>;
  private cookieNameCookieOptions = 'cookieOptions';

  constructor(private browserService: BrowserService) {
    // retrieve the currently stored cookieOptions from the cookies
    const savedCookieOptions: string = this.getCookie(this.cookieNameCookieOptions);

    // if some cookie Options were saved
    if (savedCookieOptions) {
      // parse them as the new cookieOptions
      this.updateCookieOptions(JSON.parse(savedCookieOptions));
    }
  }

  /*
  updateCookieOptions - updates the current cookie options by replacing the old ones
  */
  updateCookieOptions(cookieOptions: Array<CookieOption>) {
    this.cookieOptions = cookieOptions;

    // Store the current selection of cookie Types as a cookie
    this.setCookie(this.cookieNameCookieOptions, 'Notwendig', JSON.stringify(this.cookieOptions), 100);

    // Check if statistic cookies were chosen
    const indexStatistikOption: number = this.cookieOptions.findIndex(obj => {
      return obj.name === 'Statistik';
    });

    // If not remove all google analytics cookies
    if (indexStatistikOption === -1) {
      this.removeAnalyticsCookies();
    } else {
      this.includeAnalytics();
    }
  }

  removeAnalyticsCookies() {
    this.eraseCookie('_ga');
    this.eraseCookie('_gid');
    this.eraseCookie('_gat_gtag_UA_4705324_1');
  }

  /* tslint:disable */
  includeAnalytics() {
    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag() {
      (window as any).dataLayer.push(arguments);
    }

    // @ts-ignore
    gtag('js', new Date());

    // @ts-ignore
    gtag('config', environment.gaTrackingId, {'anonymize_ip': true});

    var gascript: any = document.createElement('script');
    gascript.async = true;
    gascript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
    // @ts-ignore
    document.getElementsByTagName('head')[0].appendChild(gascript, document.getElementsByTagName('head')[0]);
  }

  /* tslint:enable */

  /*
  getCookieOptions - returns the current array of chosen cookie options
  */
  getCookieOptions(): Array<CookieOption> {
    return this.cookieOptions;
  }

  /*
  getCookieOptions - empties the current array of chosen cookie Options
  */
  clearCookieOptions() {
    this.cookieOptions = [];
  }


  // Functions for handling cookies

  /*
  setCookie - sets a cookie with a given name and value
  */
  setCookie(name: string, type: string, value: string, days: number): void {
    if (-1 === this.cookieOptions.findIndex(obj => {
      return obj.name === type;
    })) {
      console.log('Cannot set Cookie, because type is not allowed');
      return;
    }

    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  /*
  deleteCookie - deletes a cookie given by name
  */
  eraseCookie(name: string) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  /*
  updateCookie - replaces a cookie given by name, by first deleting it and inserting it again with the new value
  */
  updateCookie(name: string, type: string, value: string, days: number) {
    this.eraseCookie(name);
    this.setCookie(name, type, value, days);
  }

  /*
  getCookie - gets the value of a cookie given by name
  */
  getCookie(name: string): string {
    if (!this.browserService.isBrowser) return;

    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /*
  resetCookiesAndReload
  */
  resetCookiesAndReload() {
    this.removeAnalyticsCookies();
    this.eraseCookie(this.cookieNameCookieOptions);
    location.reload();
  }
}
