import { Component, Input, OnInit } from '@angular/core';
import { Service } from '@twbi/types';

@Component({
  selector: 'twbi-small-service-card',
  templateUrl: './small-service-card.component.html',
  styleUrls: ['./small-service-card.component.scss']
})
export class SmallServiceCardComponent {

  @Input() service: Service;

}
