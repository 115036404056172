import { Component, Input, OnInit } from '@angular/core';
import { Picture } from '@twbi/types';

@Component({
  selector: 'twbi-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {

  @Input() heroImage: Picture;
  @Input() intro: string;
  @Input() title: string;
  @Input() subtitle: string;

  constructor() {
  }

  ngOnInit() {
  }

}
