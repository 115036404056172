import { Injectable } from '@angular/core';
import * as Prismic from 'prismic-javascript';
import { from, Observable, of } from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { ContactPerson, Employee, JobPosting, OfficeLocation, Service } from '@twbi/types';
import * as PrismicDOM from 'prismic-dom';
import { Partner } from '../../components/blue-logo-line/blue-logo-line.component';


@Injectable({
  providedIn: 'root'
})
export class CmsService {
  apiRoot = 'https://twbi.cdn.prismic.io/api/v2';

  constructor() {
  }

  getByKey(key, val): Observable<any> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => api.getByUID(key, val)),
      catchError(e => of(e))
    );
  }


  linkResolver(doc) {
    // Pretty URLs for known types
    if (doc.type === 'blog') return '/post/' + doc.uid;
    if (doc.type === 'page') return '/' + doc.uid;
    // Fallback for other types, in case new custom types get created
    return '/doc/' + doc.id;
  };

  public getByIdList(idList: string[]): Observable<any> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => api.getByIDs(idList)),
      catchError(e => of(e))
    );
  }


  public getJobPostingsByLocationId(locationId) {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query([
          Prismic.Predicates.at('document.type', 'jobposting'),
          Prismic.Predicates.at('my.jobposting.locations.location', locationId)
        ], {})
      }),
      map((resp: any) => resp.results.map(obj => ({...obj.data, slug: obj.uid})))
    );
  };

  public getLocationsByServiceId(serviceId) {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query([
          Prismic.Predicates.at('document.type', 'officelocation'),
          Prismic.Predicates.at('my.officelocation.services.service', serviceId)
        ], {})
      }),
      map((resp: any) => resp.results.map(obj => ({...obj.data, slug: obj.uid})))
    );
  };

  public getAllJobPostings(): Observable<JobPosting[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'jobposting'),
          {pageSize: 100, fetchLinks: 'officelocation.title'})
      }),
      map((resp: any) => resp.results.map(obj => ({...obj.data, slug: obj.uid, id: obj.id})))
    );
  }

  public getJobPostingsByService(service: Service): Observable<JobPosting[]> {
    return this.getAllJobPostings().pipe(
      map(jobPostings =>
      {
        return jobPostings.filter(jobPosting => service.job_postings.some(p => p.job_posting.id === jobPosting.id));
      }
      )
    )
  }

  public getAllLocations(): Observable<OfficeLocation[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'officelocation'),
          {pageSize: 100})
      }),
      map(resp => resp.results.map(obj => ({...obj.data, slug: obj.uid, id: obj.id}))),
      map(locations => locations.map(loc => ({...loc, is_headquarter: loc.is_headquarter === 'true'}))));
  }

  public getAllServices(): Observable<Service[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'service'),
          {pageSize: 100})
      }),
      map((resp: any) => resp.results.map(obj => ({
        ...obj.data,
        side_info: PrismicDOM.RichText.asHtml(obj.data.side_info, this.linkResolver),
        content: PrismicDOM.RichText.asHtml(obj.data.content, this.linkResolver),
        slug: obj.uid,
        id: obj.id
      })))
    );
  }

  public getAllPartner(): Observable<Partner[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'partner'),
          {pageSize: 100})
      }),
      map((resp: any) => resp.results.map(obj => ({
        ...obj.data,
        link: obj.data.link.url
      })))
    );
  }

  public getAllContactPersons(): Observable<ContactPerson[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'contactperson'),
          {pageSize: 100, fetchLinks: 'officelocation.title'})
      }),
      map((resp: any) => resp.results.map(obj => ({
        ...obj.data,
      })))
    );
  }

  public getAllEmployees(): Observable<Employee[]> {
    return from(Prismic.getApi(this.apiRoot)).pipe(
      switchMap(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'employee'),
          {pageSize: 100})
      }),
      map((resp: any) => resp.results.map(obj => ({
        ...obj.data,
      })))
    );
  }


  public mobileDocResolver(md: any): string {
    return PrismicDOM.RichText.asHtml(md, this.linkResolver);
  }

}
