import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'twbi-slider-row',
  templateUrl: './slider-row.component.html',
  styleUrls: ['./slider-row.component.scss'],
  animations: [trigger('fadeInOut', [
    transition(':enter', [
      style({opacity: 0}),
      animate('400ms  50ms ease-in-out', style({opacity: 1}))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({opacity: 0}))
    ])
  ])]
})

export class SliderRowComponent implements OnInit {

  @ViewChild(SwiperComponent, {static: false}) swiperRef?: SwiperComponent;
  @Input() config = {
    centeredSlides: false,
    slidesPerView: 4,
    breakpoints: {
      992: {
        slidesPerView: 1,
        centeredSlides: true
      },
      1570: {
        slidesPerView: 2,
        centeredSlides: false
      }
    }
  };

  @Input() showButton = true;
  @Input() lightButton = false;
  @Input() n;

  index = 0;


  constructor() {
  }

  ngOnInit() {
  }

  next() {
    this.index++;
  }

  prev() {
    this.index--;
  }
}
