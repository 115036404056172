import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CmsService } from '../../services/cms/cms.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Quote } from '../../components/quote-section/quote-section.component';

@Component({
  selector: 'twbi-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss']
})
export class PageTemplateComponent implements OnInit {

  page: any;


  constructor(private cmsService: CmsService,
              private route: ActivatedRoute,
              private router: Router,) {
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('slug')),
      switchMap(slug => this.cmsService.getByKey('page', slug)),
      tap(page => {
        this.page = page ? page : {type: 404};
      }),
      catchError(err => {
        return of(null);
      })
    ).subscribe();
  }

  ngOnInit() {
  }


  getQuotes(dtoQuotes): Quote[] {
    return dtoQuotes.map(dto => ({
      quote: this.cmsService.mobileDocResolver(dto.quote),
      quotee: this.cmsService.mobileDocResolver(dto.quotee)
    }));
  }

}
