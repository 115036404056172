import { Component, Input, OnInit } from '@angular/core';
import { BrowserService } from '../../../services/browser/browser.service';

@Component({
  selector: 'a-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit {

  @Input() src;
  @Input() alt;

  @Input() cloudinaryPath = 'https://res.cloudinary.com/digital-h-de/image/upload/';
  @Input() classes: string;

  @Input() autoLoad = false;
  @Input() useImgLazyLoad = true;
  @Input() width;


  fetchUrl = 'https://res.cloudinary.com/digital-h-de/image/fetch/';

  autoReplace =
    {
      from: 'https://twbi.cdn.prismic.io/twbi/',
      to: 'https://res.cloudinary.com/digital-h-de/image/upload/',
      specifier: 'twbi/remote-prismic/'
    };

  constructor(private browser: BrowserService) {
  }

  ngOnInit() {
    if (!this.browser.isBrowser) return;
    (<any>window).lazySizes.autoSizer.checkElems();
  }


  getImageUrl(width) {

    if (this.autoLoad) {
      const {prefix, suffix} = this.getUrlParts(this.src);
      return `${prefix}${this.getOptionString(width)}/${suffix}`
    }

    return `${this.fetchUrl}${this.getOptionString(width)}/${encodeURIComponent(this.src)}`;


  }

  getOptionString(width) {

    if (width === 'full') {
      return `f_auto,dpr_auto,fl_progressive`
    }

    if (width) {
      return `w_${width},f_auto,dpr_auto,fl_progressive`
    }

    return 'f_auto,dpr_auto,fl_progressive';
  }

  getUrlParts(src) {
    const suffix = src.replace(this.autoReplace.from, '').replace('https://twbi.cdn.prismic.io/twbi%2F', '');
    return {prefix: this.autoReplace.to, suffix: this.autoReplace.specifier + '/' + suffix}
  }

  max(a, b) {
    return Math.max(a, b);
  }
}
