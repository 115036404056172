import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { MaterialModule } from '../../modules/material/material.module';
import { AtomsModule } from '../atoms/atoms.module';

@NgModule({
  declarations: [
    SectionTitleComponent,
    NewsCardComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    MaterialModule
  ],
  exports: [
    SectionTitleComponent,
    NewsCardComponent
  ]
})
export class MoleculesModule {
}
