import { Component, Input, OnInit } from '@angular/core';
import { Picture } from '@twbi/types';

@Component({
  selector: 'twbi-blue-logo-line',
  templateUrl: './blue-logo-line.component.html',
  styleUrls: ['./blue-logo-line.component.scss']
})
export class BlueLogoLineComponent implements OnInit {

  @Input() title: Link;
  @Input() partner: Partner[];


  constructor() {
  }

  ngOnInit() {
  }

}


export interface Partner {
  logo: Picture;
  link: string;
  name: string;
  order: number;
}

export interface Link {
  linke_type: string;
  url: string;
}
