import { Component, OnInit } from '@angular/core';
import {
  ABOUT_US_ROUTE, CARRER_ROUTE,
  DATA_SECURITY_ROUTE,
  IMPRINT_ROUTE,
  ROOT,
  SERVICES_ROUTE
} from '../../modules/app-routing/routes';
import {CookieNoticeService} from "../../shared/services/cookie-notice/cookie-notice.service";

@Component({
  selector: 'twbi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  imprintLink = IMPRINT_ROUTE;
  dataSecurityLink = DATA_SECURITY_ROUTE;

  employeeDataSecurityLink = 'https://prismic-io.s3.amazonaws.com/twbi/0a14e6cf-b081-431f-ab46-afbd531e090e_Datenschutz-Arbeitnehmer.pdf';
  studentDataSecurityLink = 'https://prismic-io.s3.amazonaws.com/twbi/4863d91a-e84e-4fdb-8a57-7c13435c93f0_Datenschutz-Azubi.pdf';

  homeLink = ROOT;
  servicesLink = SERVICES_ROUTE;
  aboutUsLink = ABOUT_US_ROUTE;
  carrerLink = CARRER_ROUTE;


  constructor(public cookieService: CookieNoticeService) { }

  ngOnInit() {
  }

}
