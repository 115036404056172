import { Component, OnInit, ViewChild } from '@angular/core';
import { OfficeLocation } from '@twbi/types';
import * as Fuse from 'fuse.js';
import { CmsService } from '../../services/cms/cms.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BrowserService } from '../../shared/services/browser/browser.service';


@Component({
  selector: 'twbi-locations-map',
  templateUrl: './locations-map.component.html',
  styleUrls: ['./locations-map.component.scss']
})
export class LocationsMapComponent implements OnInit {

  map: any;


  locations: OfficeLocation[];
  allLocations: OfficeLocation[];
  selectedLocation: OfficeLocation;

  searchIndex: any;
  searchOptions = {
    keys: [
      'title',
      'short_title',
      'address_line_1',
      'address_line_2',
      'address_line_3'
    ],
    shouldSort: true,
    findAllMatches: false,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
  };

  @ViewChild('mapWrap', {static: true}) mapWrap;
  showMap = false;


  lazyComponent = {
    moduleId: 'lazy-map',
    selector: 'twbi-lazy-map'
  };

  constructor(private cms: CmsService, private router: Router, private breakpointObserver: BreakpointObserver, private browser: BrowserService) {

    this.cms.getAllLocations().subscribe(locations => {

      this.allLocations = locations.sort((a, b) => this.sortBy(a, b, 'short_title'));
      this.locations = this.allLocations;

      this.searchIndex = new Fuse(this.allLocations as any, this.searchOptions);
    });
  }

  ngOnInit() {

    if (!this.browser.isBrowser) return;


    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');

    if (isSmallScreen) {
      return;
    }

    if ('IntersectionObserver' in window) {
      const config = {
        root: null,
        rootMargin: '-50px',
        threshold: 0.5
      };

      const observer = new IntersectionObserver((changes, o) => {
        changes.forEach(change => {
          if (change.intersectionRatio > 0) {
            this.showMap = true;
            observer.unobserve(this.mapWrap.nativeElement);
          }
        })
      }, config);
      observer.observe(this.mapWrap.nativeElement)
    } else {
      this.showMap = true;
    }
  }

  public selectLocation(loc: OfficeLocation): void {

    this.selectedLocation = loc;
    const latlng = {lat: loc.location.latitude, lng: loc.location.longitude};


    if (loc.slug) {
      this.router.navigate(['/standorte', loc.slug]);
    }

    if (this.map) {
      this.map.panTo(latlng);
    }
  }

  search(term: string) {
    if (term === '') {
      this.locations = this.allLocations;
      return;
    }
    this.locations = this.searchIndex.search(term);
  }

  setMap(map) {
    this.map = map;
  }

  sortBy(a: any, b: any, key: string): number {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 1;
  }

}
