import { Component, OnInit } from '@angular/core';
import { Employee } from '@twbi/types';
import { CmsService } from '../../services/cms/cms.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'twbi-organigramm',
  templateUrl: './organigramm.component.html',
  styleUrls: ['./organigramm.component.scss']
})
export class OrganigrammComponent implements OnInit {

  teamByDepartment;

  team: Employee[] = [
    {name: 'Andreas Müller ', job: 'Geschäftsführer', team: 'Geschäftsleitung', hirachy: 1},
    {name: 'Hans-Jürgen Groth', job: 'Geschäftsführer', team: 'Geschäftsleitung', hirachy: 1},
    {name: 'Alexander Rommel ', job: 'Prokurist', team: 'Geschäftsleitung', hirachy: 1},

    {
      name: 'Kristian Zimmermann',
      job: 'Geschäftsleiter Arbeitsmarktdienstleistungen',
      team: 'Geschäftsleitung',
      hirachy: 2
    },
    {name: 'Michael Simon', job: 'Geschäftsleiter Produktentwicklung', team: 'Geschäftsleitung', hirachy: 2},
    {
      name: 'Anette Vonderschmitt',
      job: 'Geschäftsleiterin Qualitätsmanagement und Personalentwicklung',
      team: 'Geschäftsleitung',
      hirachy: 2
    },


    {name: 'Monika Dörr', job: 'Leitung Sekretariat', team: 'Team Verwaltung', hirachy: 1},
    {name: 'Claudia Hoeßelbarth', job: 'Sekretariat', team: 'Team Verwaltung', hirachy: 1},
    {name: 'Melanie Höchst', job: 'Sekretariat', team: 'Team Verwaltung', hirachy: 1},
    {name: 'Denise Idrizi', job: 'Auszubildende', team: 'Team Verwaltung', hirachy: 1},
    {name: 'Mandy Röder', job: 'Buchhaltung', team: 'Team Verwaltung', hirachy: 1},
    {name: 'Dr. Sabine Sachse-Schu', job: 'Assistentin Qualitätsmanagement', team: 'Team Verwaltung', hirachy: 1},


    {name: 'Peter Weingärtner', job: 'Leitung IT', team: 'Team IT', hirachy: 1},
    {name: 'Alexander Engelhardt', job: 'Team IT', team: 'Team IT', hirachy: 1},
    {name: 'Vitali Derksen', job: 'Auszubildender', team: 'Team IT', hirachy: 1},
  ];

  constructor(private cmsService: CmsService) {
    this.cmsService.getAllEmployees()
      .pipe(catchError(err => {
        this.getTeam();
        return of(err);
      }))
      .subscribe(list => {
        this.team = list;
        this.getTeam();
      });
  }

  ngOnInit() {
  }


  getTeam() {
    const t = this.team.map(u => ({...u, teamHirachy: this.getTeamHirachy(u)}));
    const teamObjByDepartment = this.grouBy(t, 'team');
    const teamByDepartment = Object.keys(teamObjByDepartment).map(team => (teamObjByDepartment[team]));

    this.teamByDepartment = teamByDepartment.map(team => {
      const byHirachy = this.grouBy(team, 'hirachy');
      return Object.keys(byHirachy).map(hirachy => (byHirachy[hirachy]));
    }).sort((a, b) => b[0][0].teamHirachy - a[0][0].teamHirachy);
  }

  grouBy(arr: any[], key): any {

    return arr.reduce((prev, cur) => {
      prev[cur[key]] = prev[cur[key]] ? [...prev[cur[key]], cur] : [cur];
      return prev;
    }, {});
  }

  getTeamHirachy(u): number {
    if (u.team === 'Geschäftsführung') return 10;
    if (u.team === 'Geschäftsleitung') return 10;
    if (u.team === 'Gebietsleitung') return 7;
    if (u.team === 'Verwaltung') return 5;
    if (u.team === 'Team Verwaltung') return 5;
    if (u.team === 'IT') return 1;
    if (u.team === 'Team IT') return 1;
    return 0;
  }
}
