import { Component, Input } from '@angular/core';

@Component({
  selector: 'a-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() progressInPerc: number;

  animationDone = false;

  counterOptions = {
    duration: 1,
    formattingFn: (n) => {
      if (n >= 99) return 'Fertig! ';
      return n + '%';
    }
  };

  getElementWidth(el) {
    return el.offsetWidth;
  }


  done(ev) {
    this.animationDone = true;
  }
}
