import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DATA_SECURITY_ROUTE } from '../../modules/app-routing/routes';
import { UtilService } from '../../services/util/util.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { take } from 'rxjs/operators';

@Component({
  selector: 'twbi-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  dataSecurityLink = DATA_SECURITY_ROUTE;

  contactFrom: FormGroup;
  siteKey = environment.recaptchaSiteKey;

  recaptcha;

  loading = false;

  progress;

  constructor(fb: FormBuilder, private util: UtilService, private http: HttpClient,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.contactFrom = fb.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      agb: new FormControl('', [Validators.requiredTrue]),
    })
  }

  ngOnInit() {
  }


  isWrong(key) {
    return this.contactFrom.get(key).invalid && this.contactFrom.get(key).touched
  }

  recaptchaResolved(ev) {
    this.recaptcha = ev;
    this.sendContactMessage();
  }


  sendContactMessage() {

    const dto = {...this.contactFrom.getRawValue(), 'g-recaptcha-response': this.recaptcha};

    this.http.post('/contact', dto).subscribe(resp => {
      this.progress = 100;
    });
  }

  startSubmit() {
    if (this.contactFrom.invalid) {
      this.util.markFormGroupTouched(this.contactFrom);
      return;
    }


    this.progress = 20;
    this.loading = true;
    this.recaptchaV3Service.execute('importantAction').pipe(take(1))
      .subscribe((token) => this.recaptchaResolved(token));
  }

  resetForm() {
    this.contactFrom.reset();
    this.loading = false;
  }


}
