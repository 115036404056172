import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { slideInOut } from '../../animations';
import { JobPosting, OfficeLocation, Service } from '@twbi/types';
import { CmsService } from '../../services/cms/cms.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, Subscription } from "rxjs";

@Component({
  selector: 'twbi-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  animations: [slideInOut, trigger('growShrink', [
    state('open', style({height: '*'})),
    state('closed', style({height: '{{height}}'}), {params: {height: '279px'}}),
    transition('open <=> closed', [
      animate('0.5s')
    ]),
  ])]
})

export class ServiceCardComponent implements OnInit, OnDestroy {

  @Input() service: Service;
  @Input() openServiceStream: Observable<string>;

  jobs: JobPosting[];
  isInternetExplorer = false;
  content = '';

  collapsed = true;
  showLocations = false;
  possibleLocations: OfficeLocation[];

  wide = window && window.matchMedia ? window.matchMedia('(min-width: 1570px)') : {matches: true};
  normal = window && window.matchMedia ? window.matchMedia('(min-width: 1200px)') : {matches: true};

  collapsedHeight = this.wide.matches ? '457px' : this.normal.matches ? '300px' : '279px';

  collapseStream: Subscription;

  constructor(private cms: CmsService) {
    cms.getAllJobPostings().subscribe(jobs => {
      this.jobs = jobs
    });
    if ( 'documentMode' in window.document ) {
      this.isInternetExplorer = true;
    }
  }

  ngOnInit() {
    // ### SCRIPT FOR IFRAME ###
    (function(e,n,s,t,r,a,c,i){
      // @ts-ignore
      e.CourseFrameObject=r,e[r]=e[r]||function(){e[r].q.push(arguments)},e[r].q=[],
      c=n.createElement(s),i=n.getElementsByTagName(s)[0],c.async=!0,c.src=t,
      i.parentNode.insertBefore(c,i)})(window,document,"script","https://vuc.ibb.com/course/assets/js/iframe_parent.min.js","cf");
    // @ts-ignore
    cf('create','5ce7b517-9ca8-472d-a73f-3f0c0a080d04','vuc.ibb.com',1);
    // ### SCRIPT FOR IFRAME ###

    this.cms.getLocationsByServiceId(this.service.id).subscribe(loc => {
      this.possibleLocations = loc;
    });
    this.cms.getJobPostingsByService(this.service).subscribe(jobPostings => {
      this.jobs = jobPostings;
    });

    // Fallback for logos
    if ( this.isInternetExplorer ) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(this.service.content, 'text/html');
      // @ts-ignore
      for ( const image of htmlDoc.querySelectorAll(('img')) ) {
        const backgroundNode = image.parentElement;
        backgroundNode.style.backgroundImage = 'url("' + image.src + '")';
        image.style.opacity = '0';
      }
      this.content = htmlDoc.body.innerHTML.toString();
    } else {
      this.content = this.service.content;
    }

    this.collapsedHeight = this.wide.matches ? '457px' : this.normal.matches ? '300px' : '279px';

    this.collapseStream = this.openServiceStream.subscribe(serviceId => {
      if(serviceId === this.service.id) {
        this.collapsed = false;
      }
    });
  }

  toggleCollapseState() {
    this.collapsed = !this.collapsed;
  }

  ngOnDestroy(): void {
    if(this.collapseStream) {
      this.collapseStream.unsubscribe();
    }
  }

}
