import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../animations';

@Component({
  selector: 'twbi-progress-overlay',
  templateUrl: './progress-overlay.component.html',
  styleUrls: ['./progress-overlay.component.scss'],
  animations: [fadeIn]
})
export class ProgressOverlayComponent implements OnInit {

  @Input() progress;

  @Input() title = 'Vielen Dank, für Ihre Bewerbung!';
  @Input() message = 'Bit für Bit wird nun hochgeladen und an unser Team weitergeleitet. Wir werden uns schnellstmöglich bei Ihnen melden.';
  @Input() btnTxt = 'Zur Startseite';

  @Output() btnClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

}
