import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BrowserService } from '../../shared/services/browser/browser.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouterHelperService {

  private history = [];

  constructor(private router: Router, private browser: BrowserService) {
    this.loadRouting();
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public setInitialScrollPositionTopOnRouteChange() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {

        if (this.getFirstLevelRoute(urlAfterRedirects) !== this.getFirstLevelRoute(this.getPreviousUrl())
          && !this.hasNamedOutlet(urlAfterRedirects) && !this.hasNamedOutlet(this.getPreviousUrl())) {
          if (this.browser.isBrowser) {
            window.scrollTo(0, 0);
          }
        }
      });
  }

  public hasNamedOutlet(url): boolean {
    return (url.indexOf('(ol') >= 0);
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || this.priorPathTreeRoute();
  }

  public goBack(): void {
    this.router.navigate([this.getPreviousUrl()]);
  }

  public getCurrentRoute(): string {
    return this.router.url;
  }

  public priorPathTreeRoute(): string {
    return this.getCurrentRoute().substring(0, this.getCurrentRoute().lastIndexOf('/'));
  }

  public getFirstLevelRoute(route: string): string {
    const firstSlashPos = route.indexOf('/');

    const restRout = route.substr(firstSlashPos + 1);
    let secondSlashPos;
    if (firstSlashPos >= 0) {
      secondSlashPos = restRout.indexOf('/', firstSlashPos + 1);
    } else {
      return '';
    }

    if (secondSlashPos >= 0) {
      return restRout.substring(firstSlashPos, secondSlashPos);
    } else {
      return restRout.substr(firstSlashPos);
    }
  }

  public closeOverlay() {
    this.router.navigate([{outlets: {ol: null}}]);
  }

  public openOverlay(key: string): void {
    this.router.navigate([{outlets: {'ol': [key]}}]);
  }

  public initAnalyticsTracking() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        if (!this.browser.isBrowser || !(window as any).gtag) return;
        (<any>window).gtag('config', environment.gaTrackingId, {
          'page_path': ev.urlAfterRedirects
        });
      });
  }
}
