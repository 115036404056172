import { NgModule } from '@angular/core';
import { MatIconModule, MatSlideToggleModule } from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    MatIconModule,
    MatSlideToggleModule
  ],
  exports: [
    MatIconModule,
    MatSlideToggleModule
  ]
})
export class MaterialModule {
}
