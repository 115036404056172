import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms/cms.service';
import { Job, JobPosting } from '@twbi/types';
import { ActivatedRoute } from '@angular/router';
import { faCalendar, faHourglass, faLocationArrow, faSuitcase } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'twbi-carrer-page',
  templateUrl: './carrer-page.component.html',
  styleUrls: ['./carrer-page.component.scss']
})
export class CarrerPageComponent implements OnInit {

  location = faLocationArrow;
  hourglass = faHourglass;
  suitcase = faSuitcase;
  calendar = faCalendar;

  jobs: Job[];
  public queryParams: any;

  constructor(private cms: CmsService, private activatedRoute: ActivatedRoute) {
    this.cms.getAllJobPostings().subscribe(jobs => {
      this.jobs = this.createJobsArray(jobs).sort(function (a, b) {
        if ( a.title < b.title ){
          return -1;
        }
        if ( a.title > b.title ){
          return 1;
        }
        return 0;
      });
    });
    const queryParams = activatedRoute.queryParams;
    queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }

  ngOnInit() {
  }

  createJobsArray(jobPostings: JobPosting[]): Job[] {
    const jobs: Job[] = [];
    for (const posting of jobPostings) {
      for (const location of posting.locations) {
        jobs.push({
          slug: posting.slug,
          id: posting.id,
          title: location.title1[0].text
            ? location.title1[0].text
            : posting.title,
          scrollTo: posting.scroll_to.id
            ? posting.scroll_to.id
            : posting.title,
          location: {
            uid: location.location.uid,
            city: location.location.data.title
              ? location.location.data.title
              : '',
            workhours: location.workhours[0].text
              ? location.workhours[0].text
              : '',
            relationship: location.relationship[0].text
              ? location.relationship[0].text
              : '',
            startingFrom: location.starting_from[0].text
              ? location.starting_from[0].text
              : '',
            isBroken: location.isBroken
          }
        })
      }
    }
    return jobs;
  }

}
