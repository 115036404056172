import { Component, Input, OnInit } from '@angular/core';
import { Picture } from '@twbi/types';


@Component({
  selector: 'twbi-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent implements OnInit {

  @Input() cloudinaryPath = 'https://res.cloudinary.com/digital-h-de/image/upload/';
  @Input() classes: string;

  @Input() autoLoad = false;

  @Input() picture: Picture;

  fetchUrl = 'https://res.cloudinary.com/digital-h-de/image/fetch/';


  autoReplace =
    {
      from: 'https://twbi.cdn.prismic.io/twbi/',
      to: 'https://res.cloudinary.com/digital-h-de/image/upload/',
      specifier: 'twbi/remote-prismic/'
    };


  constructor() {
  }

  ngOnInit() {
    // console.log(this.picture);
    (<any>window).lazySizes.autoSizer.checkElems();
  }


  getImageUrl(width) {

    if (this.autoLoad) {
      const {prefix, suffix} = this.getUrlParts(this.picture.url);
      return `${prefix}${this.getOptionString(width)}/${suffix}`
    }

    return `${this.fetchUrl}${this.getOptionString(width)}/${this.picture.url}`;


  }

  getOptionString(width) {
    if (width) {
      return `w_${width},f_auto,dpr_auto,fl_progressive`
    }

    return 'f_auto,dpr_auto,fl_progressive';
  }

  getUrlParts(src) {
    const suffix = src.replace(this.autoReplace.from, '').replace('https://twbi.cdn.prismic.io/twbi%2F', '');
    return {prefix: this.autoReplace.to, suffix: this.autoReplace.specifier + '/' + suffix}
  }

  getUrlPartsFromPrismic(src) {

  }

  max(a, b) {
    return Math.max(a, b);
  }
}
