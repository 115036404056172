import { Component, OnInit } from '@angular/core';
import { ContactPerson, JobPosting, Service } from '@twbi/types';
import { ABOUT_US_ROUTE, CARRER_ROUTE } from '../../modules/app-routing/routes';
import { CmsService } from '../../services/cms/cms.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'twbi-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  jobs: JobPosting[];
  services: Service[];

  careerLink = CARRER_ROUTE;
  aboutUsLink = ABOUT_US_ROUTE;

  contactPersons: ContactPerson[];


  constructor(private cms: CmsService) {
    this.getJobs();
    this.getServices();
    this.getContactPersons();
  }

  ngOnInit() {
  }

  getJobs() {
    this.cms.getAllJobPostings().pipe(
      filter(jobs => !!jobs),
      map(jobs => jobs.slice(0, 4))
    ).subscribe(jobs => this.jobs = jobs)
  }

  getServices() {
    this.cms.getAllServices().pipe(
      filter(services => !!services),
      map(services => services.map(s => {
        s.order = s.order || 9999;
        return s;
      }).sort((a, b) => a.order - b.order))
    ).subscribe(services => {
      this.services = services;
    })
  }

  getContactPersons() {
    this.cms.getAllContactPersons()
      .subscribe(list => this.contactPersons = list);
  }

}
