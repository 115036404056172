import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { CookieNoticeService } from '../../shared/services/cookie-notice/cookie-notice.service';
import { CookieOption } from '../../shared/services/cookie-notice/cookie-notice.type';


@Component({
  selector: 'twbi-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss'],
  animations: [
    trigger('slideInFromBottom', [
      transition(':enter', [
        style({ transform: 'translateY(200%)' }),
        animate('400ms ease-in', style({ transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateY(200%)' }))
      ]),
    ]),
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class CookieNoticeComponent implements OnInit {
  constructor(private cookieNoticeService: CookieNoticeService) { }

  @Input() text = 'Diese Seite verwendet Cookies um die Nutzererfahrung zu verbessern. Weitere Informationen finden Sie in unserer';
  @Input() link = '/datenschutz';
  @Input() linkText = 'Datenschutzerklärung';
  @Input() btnText = 'Akzeptieren';
  @Input() title = 'Cookie Hinweis';

  @Input() showBackdrop = false;

  // This array specifies which types of cookies are available on this side
  // and if they are preselected or mandatory (already checked) in the cookien notice
  // For a more detailed reference refer to the type cookieOption in the cookieNoticeService
  @Input() cookieOptions: Array<CookieOption> = [{
    name: 'Notwendig',
    checked: true,
    mandatory: true,
    available: true,
  }, {
    name: 'Statistik',
    checked: false,
    mandatory: false,
    available: true
  },
    {
      name: 'Komfort',
      checked: false,
      mandatory: false,
      available: false
    },
    {
      name: 'Personalisierung',
      checked: false,
      mandatory: false,
      available: false
    }
  ];

  showCookieNotice = false;

  ngOnInit(): void {

    if (!window) return;
    const currentCookieOptions = this.cookieNoticeService.getCookieOptions();
    if (!currentCookieOptions || !Array.isArray(currentCookieOptions) || currentCookieOptions.length === 0) {
      this.showCookieNotice = true;
    }
  }


  dismiss() {
    this.showCookieNotice = false;

    // Filter only for the selected ('checked') cookie options
    const selectedOptions = this.cookieOptions.filter(obj => {
      return obj.checked;
    });

    // update the cookie options with the selected ones
    this.cookieNoticeService.updateCookieOptions(selectedOptions);
  }
}
