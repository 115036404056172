import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OfficeLocation } from '@twbi/types';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'twbi-locations-search',
  templateUrl: './locations-search.component.html',
  styleUrls: ['./locations-search.component.scss']
})
export class LocationsSearchComponent implements OnInit, OnChanges {

  @Input() locations: OfficeLocation[];

  @Input() selectedOfficeLocation: OfficeLocation;
  @Output() gotSelected = new EventEmitter();
  @Output() newSearchTerm = new EventEmitter();


  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.selectedOfficeLocation) {
      this.scrollIntoView(changes.selectedOfficeLocation.currentValue);
    }

  }

  trackByFn(index, item) {
    return item.id;
  }

  search(term) {
    this.newSearchTerm.emit(term);
  }

  scrollIntoView(location: OfficeLocation) {
    // TODO scroll info view

  }

}
