import { Component, Input, OnInit } from '@angular/core';
import { ContactPerson } from '@twbi/types';

@Component({
  selector: 'twbi-contact-person-section',
  templateUrl: './contact-person-section.component.html',
  styleUrls: ['./contact-person-section.component.scss']
})
export class ContactPersonSectionComponent implements OnInit {

  @Input() contactPersons: ContactPerson[];

  constructor() {
  }

  ngOnInit() {
  }

  sort() {
    if (!this.contactPersons) return [];
    return this.contactPersons.sort((a, b) => {
      if (a.job_title < b.job_title) return -1;
      if (a.job_title > b.job_title) return 1;
      return 0;
    })
  }

}
