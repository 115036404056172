import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms/cms.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'twbi-partner-row-section',
  templateUrl: './partner-row-section.component.html',
  styleUrls: ['./partner-row-section.component.scss']
})
export class PartnerRowSectionComponent implements OnInit {


  partner;


  constructor(private cms: CmsService) {
    this.getPartner()
  }

  ngOnInit() {
  }

  getPartner() {
    this.cms.getAllPartner().pipe(
      filter(partner => !!partner),
      map(partners => partners.map(p => {
        p.order = p.order || 999;
        return p;
      }).sort((a, b) => a.order - b.order))
    ).subscribe(partner => this.partner = partner)
  }

}
