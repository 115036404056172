import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { expand } from './animation';
import { NewsModel } from '@twbi/types';
import { BrowserService } from '../../../services/browser/browser.service';
import { fadeInOut } from '@twbi/ui';
import { timer } from 'rxjs';

@Component({
  selector: 'twbi-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  animations: [fadeInOut, expand]
})
export class NewsCardComponent implements OnInit {


  @Input() newsItem: NewsModel;
  @Input() useImgLazyLoad = true;

  expanded = false;

  animationParams;

  isSafari;

  @ViewChild('placeholder', {static: true}) placeholder;

  constructor(private browser: BrowserService, private cdr: ChangeDetectorRef) {
    this.isSafari = this.browser.isSafari;
  }

  ngOnInit() {
  }


  expand(el) {

    const box = el.getBoundingClientRect();

    this.placeholder.nativeElement.style.width = box.width + 'px';
    this.placeholder.nativeElement.style.height = box.height + 'px';

    this.animationParams = {
      x: box.x, y: box.y, width: box.width, height: box.height, halfHeight: box.height / 2, halfWidth: box.width / 2
    };

    this.cdr.detectChanges();

    if (!this.expanded) {
      this.expanded = true;
    }

    this.cdr.detectChanges();

  }

  minimize(el) {

    if (el) {
      el.scrollTop = 0;
    }
    // this.placeholder.nativeElement.style.height = 0;


    this.expanded = false;
    this.cdr.detectChanges();

    timer(1000).subscribe(_ => {
      this.placeholder.nativeElement.style.width = 'auto';
      this.placeholder.nativeElement.style.height = 'auto';
    });

  }

  getShareLink() {
    if (!this.newsItem || !this.newsItem.actions) return null;
    const el = this.newsItem.actions.find(l => l.name === 'Share');
    if (!el) return null;
    return el.link;
  }
}
