import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'twbi-cite-card',
  templateUrl: './cite-card.component.html',
  styleUrls: ['./cite-card.component.scss']
})
export class CiteCardComponent{
  @Input() cite: string;
  @Input() author: string;
}
