import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'a-ghost-h2',
  templateUrl: './ghost-h2.component.html',
  styleUrls: ['../ghost.scss', './ghost-h2.component.scss']
})
export class GhostH2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
