import { Component, Input, OnInit } from '@angular/core';
import { Picture } from '@twbi/types';

@Component({
  selector: 'twbi-quote-section',
  templateUrl: './quote-section.component.html',
  styleUrls: ['./quote-section.component.scss']
})
export class QuoteSectionComponent implements OnInit {


  @Input() quotes: Quote[];
  @Input() picture: Picture;
  @Input() title: string;
  @Input() intro: string;

  constructor() {
  }

  ngOnInit() {
  }

}

export interface Quote {
  quote: string;
  quotee: string;
}
