import { animate, sequence, style, transition, trigger } from '@angular/animations';

const w = window ? window.matchMedia('(min-width: 800px)') : {matches: false};

export const expand = trigger('expand', [
  transition('closed => open', [
    style({
      position: 'fixed',
      top: '{{y}}px',
      left: '{{x}}px',
      width: '{{width}}px',
      height: '{{height}}px',
      transform: 'translate(0, 0)',
    }),
    animate('0.4s linear', style({
      position: 'fixed',
      top: '0',
      left: '0',
      height: '*',
      width: w.matches ? '800px' : '95%',
      transform: 'translate(50vw, 50vh) translate(-50%, -50%)'
    }))
  ]),
  transition('open => closed', [
    sequence([
      style({
        opacity: 1,
        position: 'fixed',
        top: '50vh',
        left: '50vw',
        width: w.matches ? '800px' : '95%',
        transform: 'translate(-50%, -50%)',
        zIndex: '200'
      }),
      animate('0.2s ease-in', style({
        opacity: 0,
        zIndex: '200'
      })),
      style({
        position: 'relative',
        top: '0',
        left: '0',
        width: 'auto',
        transform: 'translate(0, 0)'
      }),
      animate('0.2s ease-in', style({opacity: 1}))
    ]),
  ]),
]);
