export const ROOT = '';

export const ABOUT_US = 'about-us';
export const ABOUT_US_ROUTE = ROOT + ABOUT_US;

export const SERVICES = 'leistungen';
export const SERVICES_ROUTE = ROOT + SERVICES;

export const CARRER = 'jobs';
export const CARRER_ROUTE = ROOT + CARRER;

export const LOCATIONS = 'standorte';
export const LOCATIONS_ROUTE = ROOT + LOCATIONS;

export const NEWS = 'neuigkeiten';
export const NEWS_ROUTE = ROOT + NEWS;


export const IMPRINT = 'impressum';
export const IMPRINT_ROUTE = ROOT + IMPRINT;

export const DATA_SECURITY = 'datenschutz';
export const DATA_SECURITY_ROUTE = ROOT + DATA_SECURITY;

