import { NgModule } from '@angular/core';
import { MAT_LABEL_GLOBAL_OPTIONS, MatIconModule, MatSelectModule, MatSlideToggleModule, MatCheckboxModule } from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  exports: [
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  providers: [
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}}
  ]
})
export class MaterialModule {
}
