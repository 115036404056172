import { Pipe, PipeTransform } from '@angular/core';
import PrismicDOM from 'prismic-dom';

@Pipe({
  name: 'prismicDOM'
})
// tslint:disable-next-line:class-name
export class PrismicDomPipe implements PipeTransform {

  constructor() {

  }

  transform(value: any, args?: any): any {
    if (!value) return null;
    return PrismicDOM.RichText.asHtml(value);
  }

}
