import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {


  public isBrowser = isPlatformBrowser(this.platformId);

  w: any = this.isBrowser ? window : {};
  d: any = this.isBrowser ? document : {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }

  public isOpera = this.isBrowser && ((!!this.w.opr && !!this.w.opr.addons) || !!this.w.opera || navigator.userAgent.indexOf(' OPR/') >= 0);

// Firefox 1.0+
  public isFirefox = this.isBrowser && (typeof this.w.InstallTrigger !== 'undefined');

// Safari 3.0+ "[object HTMLElementConstructor]"
  public isSafari = this.isBrowser && (/constructor/i.test(this.w.HTMLElement) || (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!this.w['safari'] || (typeof this.w.safari !== 'undefined' && this.w.safari.pushNotification)));

// Internet Explorer 6-11
  public isIE = this.isBrowser && (/*@cc_on!@*/false || !!this.d.documentMode);

// Edge 20+
  public isEdge = this.isBrowser && (!this.isIE && !!this.w.StyleMedia);

// Chrome 1 - 71
  public isChrome = this.isBrowser && !!this.w.chrome && (!!this.w.chrome.webstore || !!this.w.chrome.runtime);

// Blink engine detection
  public isBlink = this.isBrowser && (this.isChrome || this.isOpera) && !!this.w.CSS;

}
