import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NewsModel } from '@twbi/types';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  cache = {};

  constructor(private http: HttpClient) {
  }

  getNews(): Observable<NewsModel[]> {
    if (this.cache['news']) {
      return of(this.cache['news'])
    }
    return this.http.get('/news').pipe(
      map((resp: any) => resp ? resp.data : []),
      tap(data => this.cache['news'] = data)
    );
  }
}
