import { animate, style, transition, trigger } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({height: '0', overflow: 'hidden'}),
    animate('200ms ease-in', style({height: '*'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({height: '0'}))
  ])
]);


export const zoomInOut = trigger('zoomInOut', [
  transition(':enter', [
    style({transformOrigin: 'center'}),
    animate('200ms ease-in', style({transform: 'scale(1)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'scale(0)'}))
  ])
]);



export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0}),
    animate('400ms  50ms ease-in-out', style({opacity: 1}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({opacity: 0}))
  ])
]);


export const slideInFromBottom = trigger('slideInFromBottom', [
  transition(':enter', [
    style({transform: 'translate(-50%, 200%)'}),
    animate('400ms ease-in', style({transform: 'translate(-50%, 0)'}))
  ]),
  transition(':leave', [
    animate('400ms ease-in', style({transform: 'translate(-50%, 200%)'}))
  ]),
]);
