import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Service } from '@twbi/types';
import { CmsService } from '../../services/cms/cms.service';

@Component({
  selector: 'twbi-service-slider',
  templateUrl: './service-slider.component.html',
  styleUrls: ['./service-slider.component.scss']
})
export class ServiceSliderComponent implements OnInit {

  services: Service[];


  constructor(private cms: CmsService) {
    this.getServices();
  }

  ngOnInit() {
  }

  getServices() {
    this.cms.getAllServices().pipe(
      filter(services => !!services),
      map(services => services.slice(0, 3)),
    ).subscribe(services => this.services = services)
  }

}
