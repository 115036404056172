import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilService} from '../../services/util/util.service';
import {DATA_SECURITY_ROUTE} from '../../modules/app-routing/routes';
import {JobPosting, OfficeLocation} from '@twbi/types';
import {CmsService} from '../../services/cms/cms.service';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, filter, last, map, tap} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {Router} from '@angular/router';

@Component({
  selector: 'twbi-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {


  @Input() selectedJob: JobPosting;
  @Input() queryParams: any;

  siteKey = environment.recaptchaSiteKey;
  dataSecuritylink = DATA_SECURITY_ROUTE;

  applicationForm: FormGroup;
  jobs: JobPosting[];
  allJobs: JobPosting[];
  allJobLocations: any[];

  recaptcha;
  locations: OfficeLocation[];
  allLocations: OfficeLocation[];
  files = [];

  progress;
  showProgress = false;

  @ViewChild('fileRef1', {static: true}) fileRef1;
  @ViewChild('fileRef2', {static: true}) fileRef2;
  @ViewChild('fileRef3', {static: true}) fileRef3;

  constructor(fb: FormBuilder,
              private util: UtilService,
              private cms: CmsService,
              private http: HttpClient,
              private router: Router,
              private recaptchaV3Service: ReCaptchaV3Service,
              private cdr: ChangeDetectorRef) {
    this.applicationForm = fb.group({
      job: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      file1: new FormControl(null, [Validators.required]),
      file2: new FormControl(null, [Validators.required]),
      file3: new FormControl(null, [Validators.required]),
      agb: new FormControl('', [Validators.requiredTrue]),
    });


  }

  ngOnInit() {
    if (this.selectedJob) {
      this.applicationForm.get('job').setValue(this.selectedJob.slug);
    }


    const allJobPostings = this.cms.getAllJobPostings();
    allJobPostings.subscribe(jobs => {
      this.allJobs = jobs;
      this.jobs = jobs;
      const allJobLocations = [];
      this.jobs.forEach(function (job) {
        job.locations.forEach(function (locat) {
          if (locat.location.uid !== undefined) {
            allJobLocations.push(locat.location.uid);
          }
        });
      });
      this.allJobLocations = allJobLocations;

      if (this.queryParams) {
        const preselectedJob = this.allJobs.find(job => job.title === this.queryParams.job);
        this.applicationForm.get('job').setValue(preselectedJob);
      }

    });

    const allLocations = this.cms.getAllLocations();
    allLocations.subscribe(loc => {
      this.allLocations = loc;
      const tempLocations = this.allLocations.map(l => l.slug);

      if (this.queryParams) {
        const preselectedLocation = this.allLocations.find(el => el.title === this.queryParams.loc);
        this.applicationForm.get('location').setValue(preselectedLocation);
      }
    });

    combineLatest(allLocations, allJobPostings).subscribe(([locations, jobpostings]) => {
      this.locations = this.allLocations.filter(el => this.allJobLocations.includes(el.slug));
    });
  }

  locationChanged(location: OfficeLocation) {
    if (!location || !location.services) return;

    this.jobs = this.allJobs.filter(job => job.locations.some(loc => loc.location.id === location.id));
  }

  isWrong(key) {
    return this.applicationForm.get(key).invalid && this.applicationForm.get(key).touched
  }

  recaptchaResolved(ev) {
    this.recaptcha = ev;

    this.sendApplication();
  }


  sendApplication() {
    const raw = this.applicationForm.getRawValue();
    const dto = {...raw, job: raw.job.title, location: raw.location.title, 'g-recaptcha-response': this.recaptcha};

    const formData = new FormData();

    formData.append('letter', this.files[1], this.files[1].name);
    formData.append('cv', this.files[2], this.files[2].name);
    formData.append('work', this.files[3], this.files[3].name);

    Object.keys(dto).map(key => {
      formData.append(key, dto[key]);
    });

    this.showProgress = true;
    this.progress = 10;

    const req = new HttpRequest('POST', '/application', formData, {
      reportProgress: true
    });

    this.http.request(req).pipe(
      map(ev => this.getEventMessage(ev)),
      filter(el => !!el),
      tap(message => this.progress = message),
      last(), // return last (completed) message to caller
      catchError(err => {
        console.log(err);
        return of(err)
      })
    ).subscribe(progress => {

    });

  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0;

      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return percentDone;

      case HttpEventType.Response:
        return 100;

      default:
        return null;
    }
  }

  onFileChange(fileIndex, ev: any) {
    if (ev.target.files.length > 0) {
      this.files[fileIndex] = ev.target.files[0];

      if (fileIndex === 1) {
        this.fileRef1.nativeElement.value = this.files[fileIndex].name
      }
      if (fileIndex === 2) {
        this.fileRef2.nativeElement.value = this.files[fileIndex].name
      }
      if (fileIndex === 3) {
        this.fileRef3.nativeElement.value = this.files[fileIndex].name
      }
    }

    this.cdr.markForCheck();
  }

  startSubmission() {

    if (this.applicationForm.invalid) {
      this.util.markFormGroupTouched(this.applicationForm);
      return;
    }

    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => this.recaptchaResolved(token));
  }

  afterSubmissionBtnClicked() {
    this.router.navigateByUrl('/');
  }

  isFormInvalid() {
    return !this.applicationForm.valid;
  }
}
