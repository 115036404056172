import { animate, group, query, state, style, transition, trigger } from '@angular/animations';


export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({height: '0', overflow: 'hidden'}),
    animate('2s ease-in', style({height: '*'}))
  ]),
  transition(':leave', [
    animate('2s ease-in', style({height: '0'}))
  ])
]);


export const zoomInOut = trigger('zoomInOut', [
  transition(':enter', [
    style({transformOrigin: 'center'}),
    animate('200ms ease-in', style({transform: 'scale(1)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'scale(0)'}))
  ])
]);


export const fadeInFromLeft = trigger('fadeInFromLeft', [
  transition(':enter', [
    style({right: '20px', opacity: '0'}),
    animate('200ms ease-in', style({right: '0', opacity: '1'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({right: '-20px', opacity: '0'}))
  ])
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({opacity: '0'}),
    animate('200ms ease-in', style({opacity: '1'}))
  ])
  // transition(':leave', [
  //   animate('200ms ease-in', style({opacity: '0'}))
  // ])
]);


export const routeAnimations =
  trigger('routeAnimations', [
    transition('* => Overlay', [
      query('.overlay', [
        style({opacity: 0})
      ], {optional: true}),
      query('.overlay__body, .overlay__footer', [
        style({transform: 'translate(-50%, -40%)'})
      ], {optional: true}),
      group([
        query('.overlay', [
          animate('300ms ease-out', style({opacity: 1}))
        ], {optional: true}),
        query('.overlay__body, .overlay__footer', [
          animate('300ms ease-out', style({transform: 'translate(-50%, -50%)'}))
        ], {optional: true}),
      ]),
    ]),

    transition('Overlay => *', [
      query('.overlay', [
        style({opacity: 1})
      ], {optional: true}),
      group([
        query('.overlay', [
          animate('200ms ease-out', style({opacity: 0}))
        ], {optional: true})
      ]),
    ])
  ]);


export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(
      ':enter .page-wrapper',
      [style({opacity: 0})],
      {optional: true}
    ),
    query(
      ':leave .page-wrapper',
      [
        style({opacity: 1, position: 'absolute', width: '100%', top: '0px', left: 0}),
        animate('0.3s', style({opacity: 0}))],
      {optional: true}
    ),
    query(
      ':enter .page-wrapper',
      [style({opacity: 0}), animate('0.3s', style({opacity: 1}))],
      {optional: true}
    )
  ])
]);
