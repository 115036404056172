import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ABOUT_US_ROUTE,
  CARRER_ROUTE,
  DATA_SECURITY_ROUTE,
  IMPRINT_ROUTE,
  LOCATIONS_ROUTE,
  NEWS_ROUTE,
  ROOT,
  SERVICES_ROUTE
} from '../../modules/app-routing/routes';
import { respMenuAnimation } from './animation';
import { MatMenu, MatMenuContent, MatMenuTrigger } from "@angular/material";
import { CmsService } from "../../services/cms/cms.service";
import { map } from "rxjs/operators";

@Component({
  selector: 'twbi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [respMenuAnimation]
})
export class HeaderComponent implements OnInit {


  homeLink = ROOT;
  aboutUslink = ABOUT_US_ROUTE;
  servicesLink = SERVICES_ROUTE;
  carrerLink = CARRER_ROUTE;
  locationsLink = LOCATIONS_ROUTE;

  imprintLink = IMPRINT_ROUTE;
  dataSecurityLink = DATA_SECURITY_ROUTE;

  isMenuOpen = false;
  dropdownEntries = [];

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  @ViewChild(MatMenu, { static: false }) content: MatMenuTrigger;

  openDropdown() {
    this.trigger.openMenu();
    document.querySelector('.mat-menu-panel').addEventListener('mouseleave', () => {
      this.closeDropdown();
    })
  }

  closeDropdown() {
    this.trigger.closeMenu();
  }

  constructor( private cmsService: CmsService ) {
  }

  ngOnInit() {
    this.cmsService.getAllServices().pipe(
      map(services => services.map(s => {
        s.order = s.order || 9999;
        return s;
      }).sort((a, b) => a.order - b.order))
    ).toPromise().then( services => {
      this.dropdownEntries = services;
    } );
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
