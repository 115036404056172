// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  showLogs: true,
  directusApiEnpoint: '/api',
  recaptchaSiteKey: '6LeMdqwUAAAAAA1oyPQCLmPmXdtMiVxR6AEm2Pyv',
  firebaseConfig: {
    apiKey: "AIzaSyDJJokCNetBwtLoYz06fsMNUqFlPwuYnaQ",
    authDomain: "twbi-prod.firebaseapp.com",
    databaseURL: "https://twbi-prod.firebaseio.com",
    projectId: "twbi-prod",
    storageBucket: "twbi-prod.appspot.com",
    messagingSenderId: "256242400603",
    appId: "1:256242400603:web:39715af1b7d9ada045ae7e"
  },
  gaTrackingId: 'UA-144828567-1',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
