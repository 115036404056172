import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoCardComponent } from './info-card/info-card.component';
import { SliderRowComponent } from './slider-row/slider-row.component';
import { CiteCardComponent } from './cite-card/cite-card.component';
import { JobCardComponent } from './job-card/job-card.component';
import { ContactPersonCardComponent } from './contact-person-card/contact-person-card.component';
import { MaterialModule } from '../material/material.module';
import { EmployeeCardComponent } from './employee-card/employee-card.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PictureComponent } from './picture/picture.component';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { HeroSectionComponent } from './hero-section/hero-section.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  centerInsufficientSlides: true
};

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    BrowserAnimationsModule,
    SwiperModule
  ],
  declarations: [
    InfoCardComponent,
    SliderRowComponent,
    SliderRowComponent,
    CiteCardComponent,
    JobCardComponent,
    ContactPersonCardComponent,
    EmployeeCardComponent,
    PictureComponent,
    HeroSectionComponent
  ],
  exports: [
    InfoCardComponent,
    SliderRowComponent,
    CiteCardComponent,
    JobCardComponent,
    ContactPersonCardComponent,
    EmployeeCardComponent,
    PictureComponent,
    HeroSectionComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class UiModule {
}
