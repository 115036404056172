import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'a-ghost-h3',
  templateUrl: './ghost-h3.component.html',
  styleUrls: ['../ghost.scss', './ghost-h3.component.scss']
})
export class GhostH3Component {

  @Input() color;

}
