import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private db: AngularFirestore) {
  }

  getPosts() {
    const path = environment.production ? 'posts' : 'posts_preview';
    return this.db.collection(path).valueChanges({idField: 'id'});
  }
}
