import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { NewsModel } from '@twbi/types';
import { UtilService } from '../../services/util/util.service';
import { BlogService } from '../../services/blog/blog.service';


@Component({
  selector: 'twbi-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {

  news: NewsModel[] | any[];
  // updateLayout = false;

  timer$;

  constructor() {
  }

  ngOnInit(): void {
    // PREVIOUS CODE FOR DYNAMIC NEWS
    /*this.newsService.getNews()
      .pipe(
        map(list => list.filter(n => n.message || n.full_picture)),
        map(list => list.map(el => ({...el, type: 'news', id: this.util.getRandomString(5)}))),
        take(1)
      )
      .subscribe(news => {
        this.addNews(news);
        this.updateLayout = true;
      });


    this.blog.getPosts().pipe(
      take(1),
      map((posts: any) => <NewsModel[]>posts.map(post => ({
        ...post,
        status_stype: 'post',
        full_picture: post.image,
        created_time: post.published_at,
        message: post.text
      })))
    ).subscribe(posts => {
      this.addNews(posts);
    });

    this.timer$ = timer(1000).subscribe(_ => {
      this.updateLayout = !this.updateLayout;
    })*/
  }

  /*
  trackByFn(index, item) {
    return item.id;
  }

  addNews(news: NewsModel[]) {
    if (this.news) {
      this.news = [...this.news, ...news];
    } else {
      this.news = news;
    }
  }

  ngOnDestroy(): void {
    this.timer$.unsubscribe();
  }*/
}
