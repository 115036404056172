import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { CarrerPageComponent } from './pages/carrer-page/carrer-page.component';
import { LocationsPageComponent } from './pages/locations-page/locations-page.component';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';
import { DataSecurityPageComponent } from './pages/data-security-page/data-security-page.component';
import { AppRoutingModule } from './modules/app-routing/app-routing.module';
import { LocationsMapComponent } from './components/locations-map/locations-map.component';
import { MaterialModule } from './modules/material/material.module';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LocationsSearchComponent } from './components/locations-map/sub-components/locations-search/locations-search.component';
import { LocationsEntryComponent } from './components/locations-map/sub-components/locations-entry/locations-entry.component';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { UtilService } from './services/util/util.service';
import { LegendComponent } from './components/locations-map/sub-components/legend/legend.component';
import { JobDialogComponent } from './components/job-dialog/job-dialog.component';
import { SmallServiceCardComponent } from './components/small-service-card/small-service-card.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { RouterHelperService } from './services/router-helper/router-helper.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { LoadingComponent } from './components/loading/loading.component';
import { PageTemplateComponent } from './pages/page-template/page-template.component';
import { ServiceSliderComponent } from './components/service-slider/service-slider.component';
import { QuoteSectionComponent } from './components/quote-section/quote-section.component';
import { BlueLogoLineComponent } from './components/blue-logo-line/blue-logo-line.component';
import { JobsSectionComponent } from './sections/jobs-section/jobs-section.component';
import { ContactPersonSectionComponent } from './sections/contact-person-section/contact-person-section.component';
import { ContactFormSectionComponent } from './sections/contact-form-section/contact-form-section.component';
import { NewsRowSectionComponent } from './sections/news-row-section/news-row-section.component';
import { LocationInfoBlockComponent } from './components/location-info-block/location-info-block.component';
import { AtomsModule } from './shared/atoms/atoms.module';
import { OrganigrammComponent } from './components/organigramm/organigramm.component';
import { ErrPageComponent } from './pages/err-page/err-page.component';
import { MoleculesModule } from './shared/molecules/molecules.module';
import { ApplicationDialogComponent } from './components/application-dialog/application-dialog.component';
import { UiModule } from '@twbi/ui';
import { HttpClientModule } from '@angular/common/http';
import { PartnerRowSectionComponent } from './sections/partner-row-section/partner-row-section.component';
import { BrowserService } from './shared/services/browser/browser.service';
import { environment } from '../environments/environment';
import { ProgressOverlayComponent } from './components/progress-overlay/progress-overlay.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReactiveComponentLoaderModule } from '@wishtack/reactive-component-loader';
import { PrismicDomPipe } from './shared/pipes/prismic-dom.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CookieNoticeComponent } from './components/cookie-notice/cookie-notice.component';
import { PrismicDomTextPipe } from './shared/pipes/prismicDomText/prismic-dom-text.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NoSanitizePipe } from './shared/pipes/no-sanitize.pipe';
import { MatMenuModule } from "@angular/material";


@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    AboutUsPageComponent,
    ServicesPageComponent,
    CarrerPageComponent,
    LocationsPageComponent,
    ImprintPageComponent,
    DataSecurityPageComponent,
    LocationsMapComponent,
    ContactFormComponent,
    FooterComponent,
    HeaderComponent,
    LocationsSearchComponent,
    LocationsEntryComponent,
    ApplicationFormComponent,
    ServiceCardComponent,
    LegendComponent,
    JobDialogComponent,
    SmallServiceCardComponent,
    NewsPageComponent,
    LoadingComponent,
    PageTemplateComponent,
    ServiceSliderComponent,
    QuoteSectionComponent,
    BlueLogoLineComponent,
    JobsSectionComponent,
    ContactPersonSectionComponent,
    ContactFormSectionComponent,
    NewsRowSectionComponent,
    LocationInfoBlockComponent,
    OrganigrammComponent,
    ErrPageComponent,
    ApplicationDialogComponent,
    PartnerRowSectionComponent,
    ProgressOverlayComponent,
    PrismicDomPipe,
    CookieNoticeComponent,
    PrismicDomTextPipe,
    NoSanitizePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    UiModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMasonryModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    AtomsModule,
    MoleculesModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ReactiveComponentLoaderModule.forRoot(),
    ReactiveComponentLoaderModule.withModule({
      moduleId: 'lazy-map',
      loadChildren: './components/lazy-map/lazy-map.module#LazyMapModule'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FontAwesomeModule,
    MatMenuModule
  ],
  providers: [
    UtilService, RouterHelperService, BrowserService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
