import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CmsService} from '../../services/cms/cms.service';
import {map, switchMap} from 'rxjs/operators';
import {JobPosting} from '@twbi/types';
import * as PrismicDOM from 'prismic-dom';

@Component({
  selector: 'twbi-job-dialog',
  templateUrl: './job-dialog.component.html',
  styleUrls: ['./job-dialog.component.scss']
})
export class JobDialogComponent implements OnInit {

  job: JobPosting;

  constructor(private cms: CmsService, private router: Router, private route: ActivatedRoute) {
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('slug')),
      switchMap(slug => this.cms.getByKey('jobposting', slug))
    ).subscribe((resp: any) => {
      this.job = <JobPosting>{
        ...resp.data,
        slug: resp.uid,
        job_description: PrismicDOM.RichText.asHtml(resp.data.job_description, this.cms.linkResolver),
      };

    });
  }

  ngOnInit() {
  }

  public closeOverlay() {
    this.router.navigate([{outlets: {ol: null}}]);
  }

  openApplication() {

      this.router.navigate([{outlets: {"ol": null}}]).then(a => this.router.navigate(['/jobs']));

    //
  }
}
