import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhostPictureComponent } from './components/ghosts/ghost-picture/ghost-picture.component';
import { GhostSpanComponent } from './components/ghosts/ghost-span/ghost-span.component';
import { GhostH3Component } from './components/ghosts/ghost-h3/ghost-h3.component';
import { GhostH1Component } from './components/ghosts/ghost-h1/ghost-h1.component';
import { GhostPComponent } from './components/ghosts/ghost-p/ghost-p.component';
import { GhostH2Component } from './components/ghosts/ghost-h2/ghost-h2.component';
import { BurgerIconComponent } from './components/burger-icon/burger-icon.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { GhostCardComponent } from './components/ghosts/ghost-card/ghost-card.component';
import { PictureComponent } from './components/picture/picture.component';
import { CountUpModule } from 'countup.js-angular2';
import { FileValueAccessor } from './file-contral-value-accessor';

@NgModule({
  declarations: [
    GhostH1Component,
    GhostH2Component,
    GhostPComponent,
    GhostPictureComponent,
    GhostSpanComponent,
    GhostH3Component,
    BurgerIconComponent,
    ProgressBarComponent,
    GhostCardComponent,
    PictureComponent,
    FileValueAccessor
  ],
  imports: [
    CommonModule,
    CountUpModule
  ],
  exports: [
    GhostH1Component,
    GhostH2Component,
    GhostPComponent,
    GhostPictureComponent,
    GhostSpanComponent,
    GhostH3Component,
    BurgerIconComponent,
    ProgressBarComponent,
    GhostCardComponent,
    PictureComponent,
    FileValueAccessor
  ]
})
export class AtomsModule {
}
