import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { OfficeLocation } from '@twbi/types';
import { LocationsMapComponent } from '../../components/locations-map/locations-map.component';
import { CmsService } from '../../services/cms/cms.service';
import PrismicDOM from 'prismic-dom';


@Component({
  selector: 'twbi-locations-page',
  templateUrl: './locations-page.component.html',
  styleUrls: ['./locations-page.component.scss']
})
export class LocationsPageComponent implements OnInit {

  location: OfficeLocation;

  services: any[];
  jobs: any[];

  @ViewChild('locationsMap', {static: false}) locationsMap: LocationsMapComponent;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cms: CmsService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('slug')),
      tap(slug => this.location = null),
      switchMap(slug => this.cms.getByKey('officelocation', slug))
    ).subscribe((resp: any) => {
      if (resp && resp.data) {
        this.location = <OfficeLocation>{
          ...resp.data,
          id: resp.id,
          description: PrismicDOM.RichText.asHtml(resp.data.description, this.cms.linkResolver),
          besonderheiten: PrismicDOM.RichText.asHtml(resp.data.besonderheiten, this.cms.linkResolver),
          anfahrt: PrismicDOM.RichText.asHtml(resp.data.anfahrt, this.cms.linkResolver),
        };

        if (this.locationsMap) {
          this.locationsMap.selectLocation(this.location);
        }

        const serviceIdList = this.location.services.map((serviceIndex: any) => serviceIndex.service.id);
        this.getServices(serviceIdList);

        this.getJobPostings(resp.id);

        this.cdr.detectChanges();
      }
    });
  }


  getServices(ids: string[]) {
    this.cms.getByIdList(ids).subscribe(resp => {
        if (resp.results) {
          this.services = resp.results.map(serviceObj => serviceObj.data)
        }
      }
    );
  }

  getJobPostings(locationId: string) {
    this.cms.getJobPostingsByLocationId(locationId).subscribe(jobs => {
      this.jobs = jobs;
    })
  }

}
