import { Component, OnInit } from '@angular/core';
import { NewsModel } from '@twbi/types';
import { map, take } from 'rxjs/operators';
import { NewsService } from '../../services/news/news.service';
import { BlogService } from '../../services/blog/blog.service';

@Component({
  selector: 'twbi-news-row-section',
  templateUrl: './news-row-section.component.html',
  styleUrls: ['./news-row-section.component.scss']
})
export class NewsRowSectionComponent implements OnInit {

  news: NewsModel[];


  constructor(private newsService: NewsService, private blog: BlogService) {
    this.newsService.getNews()
      .pipe(

      )
      .subscribe(news => {
        this.addNews(news);
      });

    this.blog.getPosts().pipe(
      take(1),
      map((posts: any) => <NewsModel[]>posts.map(post => ({
        ...post,
        status_stype: 'post',
        full_picture: post.image,
        created_time: post.published_at,
        message: post.text
      })))
    ).subscribe(posts => {
      this.addNews(posts);
    });
  }

  ngOnInit() {
  }

  sortByDate(a, b) {
    const dateA = new Date(a.created_time).getTime();
    const dateB = new Date(b.created_time).getTime();

    return dateB - dateA;
  };

  addNews(news: NewsModel[]) {

    let newNews = [];
    if (this.news) {
      newNews = [...this.news, ...news];
    } else {
      newNews = news;
    }

    newNews = newNews.filter(n => !!n.full_picture);
    newNews = newNews.sort(this.sortByDate);
    this.news = newNews.slice(0, 4);
  }

}
