import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'a-ghost-p',
  templateUrl: './ghost-p.component.html',
  styleUrls: ['../ghost.scss', './ghost-p.component.scss']
})
export class GhostPComponent {

  @Input() n = 4;


  getRows() {
    return Array.from(Array(Number(this.n)).keys())
  }

}
