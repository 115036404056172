import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms/cms.service';
import { filter, map } from 'rxjs/operators';
import { JobPosting } from '@twbi/types';
import { CARRER_ROUTE } from '../../modules/app-routing/routes';

@Component({
  selector: 'twbi-jobs-section',
  templateUrl: './jobs-section.component.html',
  styleUrls: ['./jobs-section.component.scss']
})
export class JobsSectionComponent implements OnInit {

  @Input() title: string;
  @Input() intro: string;

  jobs: JobPosting[];

  careerLink = CARRER_ROUTE;

  constructor(private cms: CmsService) {
    this.getJobs();
  }

  ngOnInit() {
  }

  getJobs() {
    this.cms.getAllJobPostings().pipe(
      filter(jobs => !!jobs),
      map(jobs => jobs.slice(0, 4))
    ).subscribe(jobs => this.jobs = jobs)
  }
}
