import { Component, OnInit } from '@angular/core';
import { Service } from '@twbi/types';
import { CmsService } from '../../services/cms/cms.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';


@Component({
  selector: 'twbi-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  services: Service[];
  openServiceStream = new BehaviorSubject(null);

  constructor(cms: CmsService, private activatedRoute: ActivatedRoute) {
    const asyncTask1 = cms.getAllServices().pipe(
      map(services => services.map(s => {
        s.order = s.order || 9999;
        return s;
      }).sort((a, b) => a.order - b.order))
    );

    const asyncTask2 = this.activatedRoute.queryParams;

    combineLatest(asyncTask1, asyncTask2).subscribe(([services, params]) => {
      this.services = services;
      const service = params['service'];
      setTimeout( () => {
        if (service) {
          let pos = document.getElementById(service).offsetTop;
          pos -= 100;
          window.scrollTo({top: pos, behavior: 'smooth'});
          this.openServiceStream.next(service);
        }
      }, 1000);
    });
  }


  ngOnInit() {

  }

}
