import { Pipe, PipeTransform } from '@angular/core';
import PrismicDOM from 'prismic-dom';

@Pipe({
  name: 'prismicDomText'
})

export class PrismicDomTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    return PrismicDOM.RichText.asText(value);
  }

}
